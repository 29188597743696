// Development
// const API_GATEWAY_URL = "http://190.183.61.32:8080";
// Demo
//const API_GATEWAY_URL = 'https://demo.gateway.mediline.com.ar';
// Production
// const API_GATEWAY_URL = "https://gateway.mediline.com.ar";

const API_GATEWAY_URL =
  process.env.REACT_APP_GATEWAY_URL || REACT_APP_GATEWAY_URL;
const SERVICES_PATH = `${API_GATEWAY_URL}/services`;

//GATEWAY
const GATEWAY_AUTHENTICATE_ENDPOINT = `${API_GATEWAY_URL}/api/authenticate`;
const GATEWAY_REGISTER_ENDPOINT = `${API_GATEWAY_URL}/api/register`;
const GATEWAY_ACCOUNT_ENDPOINT = `${API_GATEWAY_URL}/api/account`;
const GATEWAY_USER_ENDPOINT = `${API_GATEWAY_URL}/api/user`;

//USER PROFILE NEW ENDPOINTS
const USER_PROFILE_API_PATH = `${SERVICES_PATH}/userprofile/api`;
const USERPROFILE_QUEUE_ENDPOINT = `${USER_PROFILE_API_PATH}/patients-queue`;
const PACIENT_STUDY_ENDPOINT = `${USER_PROFILE_API_PATH}/patient/study`;
const PACIENT_MEMBER_STUDY_ENDPOINT = `${USER_PROFILE_API_PATH}/patient/member/study`;
const USER_SPEEDTEST_API_ENDPOINT = `${SERVICES_PATH}/userprofile/api/speedtest`;
const PROFESSIONAL_USER_PROFILE_ENDPOINT = `${USER_PROFILE_API_PATH}/professionals`;
const PROFESSIONAL_BS_USER_PROFILE_ENDPOINT = `${USER_PROFILE_API_PATH}/business/professionals`;
const PROFESSIONAL_BS_SEARCH_ENDPOINT = `${PROFESSIONAL_BS_USER_PROFILE_ENDPOINT}/search`;
const PROFESSIONAL_BS_PREPAID_HEALTH_ENDPOINT = `${PROFESSIONAL_BS_USER_PROFILE_ENDPOINT}/prepaid-health`;
const PATIENT_USER_PROFILE_ENDPOINT = `${USER_PROFILE_API_PATH}/patients`;
const PATIENT_MEDICATION_ENDPOINT = `${USER_PROFILE_API_PATH}/patient-frequent-medications/patient/`;
const FAMILY_GROUP_USER_PROFILE_ENDPOINT = `${USER_PROFILE_API_PATH}/family_groups`;
const PATIENT_BS_USER_PROFILE_ENDPOINT = `${USER_PROFILE_API_PATH}/business/patients/search`;
const PHARMACIST_USER_PROFILE_ENDPOINT = `${USER_PROFILE_API_PATH}/pharmacists`;
const USER_DATA_ENDPOINT = `${USER_PROFILE_API_PATH}/user-data`;
//const PATIENT_MEMBER_USER_PROFILE_ENDPOINT = `${USER_PROFILE_API_PATH}/patients/member`;
const PATIENT_CHANGE_PLAN_ENDPOINT = `${USER_PROFILE_API_PATH}/change-plan`;

//VADEMECUM
const VADEMECUM_SERVICE_PATH = `${SERVICES_PATH}/vademecum/api`;
const VADEMECUM_MEDICATION_ENDPOINT = `${VADEMECUM_SERVICE_PATH}/medications`;
const VADEMECUM_MEDICATION_SEARCH_ENDPOINT = `${VADEMECUM_MEDICATION_ENDPOINT}/search`;

//PHARMACY
const PHARMACY_SERVICE_PATH = `${SERVICES_PATH}/pharmacy/api`;
const PHARMACY_BRANCH_OFFICES_PHARMACIES_ENDPOINT = `${PHARMACY_SERVICE_PATH}/business/branch-offices/pharmacies`;
const PHARMACY_BRANCH_OFFICES_PHARMACIES_SEARCH_ENDPOINT = `${PHARMACY_SERVICE_PATH}/business/branch-offices/pharmacies/search`;
const PHARMACY_PURCHASE_ORDERS_ENDPOINT = `${PHARMACY_SERVICE_PATH}/business/purchase-orders`;
const PHARMACY_PURCHASE_ORDERS_SEARCH_ENDPOINT = `${PHARMACY_SERVICE_PATH}/business/purchase-orders/search`;
const PHARMACY_PRESCRIPTIONS_PURCHARSE_ORDERS_ENDPOINT = `${PHARMACY_SERVICE_PATH}/business/prescriptions/purchase-orders`;
const PHARMACY_PRESCRIPTION_MEDICATIONS_PRESCRIPTIONS_ENDPOINT = `${PHARMACY_SERVICE_PATH}/business/prescription-medications/prescriptions`;
const PHARMACY_EXTRAS_PURCHASE_ORDERS_ENDPOINT = `${PHARMACY_SERVICE_PATH}/business/extras/purchase-orders`;

//CONSULTATION
const CONSULTATION_SERVICE_PATH = `${SERVICES_PATH}/consultation/api`;
const CONSULTATION_ENDPOINT = `${CONSULTATION_SERVICE_PATH}/consultations`;
const CONSULTATION_ENDPOINT_BUSINESS = `${CONSULTATION_SERVICE_PATH}/business/consultations`;
const AGORA_CONSULTATION_ENDPOINT_BUSINESS = `${CONSULTATION_SERVICE_PATH}/business/agoraconsultations`;
const CONSULTATION_ENDPOINT_BUSINESS_BY_PROFESSIONAL = `${CONSULTATION_ENDPOINT_BUSINESS}/professional`;
const CONSULTATION_PATIENT_ENDPOINT = `${CONSULTATION_SERVICE_PATH}/business/consultations/patient`;
const CONSULTATION_RANKING_ENDPOINT= `${CONSULTATION_SERVICE_PATH}/consultations/queue`;

//APPOINTMENT
const APPOINTMENT_SERVICE_PATH = `${SERVICES_PATH}/appointment`;
const APPOINTMENT_ENDPOINT = `${APPOINTMENT_SERVICE_PATH}/api/appointments`;
const APPOINTMENT_MY_APPOINTMENTS_ENDPOINT = `${APPOINTMENT_ENDPOINT}/my-appointments`;

//SCHEDULE
const SCHEDULE_SERVICE_PATH = `${APPOINTMENT_SERVICE_PATH}/api/schedules`;
const SCHEDULE_AVAILABILITY_ENDPOINT = `${SCHEDULE_SERVICE_PATH}/availability`;

//TIME SLOT
const TIME_SLOT_AVAILABILITY_ENDPOINT = `${APPOINTMENT_SERVICE_PATH}/api/time-slots`;

//CHIME
const CHIME_SERVICE_PATH = `${SERVICES_PATH}/consultation/api/business/chime`;
const CHIME_JOIN_ENDPOINT = `${CHIME_SERVICE_PATH}/join`;
const CHIME_ATTENDEE_ENDPOINT = `${CHIME_SERVICE_PATH}/attendee`;
const CHIME_END_ENDPOINT = `${CHIME_SERVICE_PATH}/end`;

//NOTIFICATION
const NOTIFICATION_SERVICE_PATH = `${SERVICES_PATH}/notification/api/notification-deliveries`;
const NOTIFICATION_SERVICE_ENDPINT = `${SERVICES_PATH}/notification/api/notifications`;
const NOTIFICATION_TEMPLATES_VIEW_SERVICE_ENDPOINT = `${SERVICES_PATH}/notification/api/notification-templates/view`;
const NOTIFICATION_DELIVERIES_BY_USERLOGIN_SERVICE_ENDPOINT = `${SERVICES_PATH}/notification/api/notification-deliveries/userLogin`;
const NOTIFICATION_QUANTITY_ENDPOINT = `${NOTIFICATION_SERVICE_PATH}/quantity`;

//PRESCRIPTION
const PRESCRIPTION_PATIENT_ENDPOINT = `${CONSULTATION_SERVICE_PATH}/business/prescriptions/patient`;

//PARAMETRICS
const PARAMETRICS_SERVICE_PATH = `${SERVICES_PATH}/parametrics`;
const PARAMETRICS_SURGERY_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/surgeries`;
const PARAMETRICS_SURGERY_SEARCH_ENDPOINT = `${PARAMETRICS_SURGERY_ENDPOINT}/search`;
const PARAMETRICS_PREEXISTING_DISEASES_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/pre-existing-diseases`;
const PARAMETRICS_PREEXISTING_DISEASES_SEARCH_ENDPOINT = `${PARAMETRICS_PREEXISTING_DISEASES_ENDPOINT}/search`;
const PARAMETRICS_PREPAID_HEALTH_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/prepaid-healths`;
const PARAMETRICS_PREPAID_HEALTH_SEARCH_ENDPOINT = `${PARAMETRICS_PREPAID_HEALTH_ENDPOINT}/search`;
const PARAMETRICS_PREPAID_HEALTH_CATEGORIES_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/prepaid-health-categories`;
const PARAMETRICS_SPECIALITIES_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/specialities`;
const PARAMETRICS_SPECIALITIES_ALL_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/specialities/all`;
const PARAMETRICS_SPECIALITIES_SEARCH_ENDPOINT = `${PARAMETRICS_SPECIALITIES_ENDPOINT}/search`;
const PARAMETRICS_FINANCER_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/financers`;
const PARAMETRICS_FINANCER_PLAN_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/financer-plans`;
const PARAMETRICS_PATIENT_FINANCER_PLANS_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/patient-financer-plans`;
const PARAMETRICS_LOCATION_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/locations`;
const PARAMETRICS_PROVINCE_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/provinces`;
const PARAMETRICS_LOG_ENDPOINT = `${PARAMETRICS_SERVICE_PATH}/api/event-log`;

//QUEUE
const PATIENT_QUEUE_SERVICE_PATH = `${SERVICES_PATH}/queue`;
const QUEUE_PATIENTS_QUEUE_ENDPOINT = `${PATIENT_QUEUE_SERVICE_PATH}/api/patients-queue`;
const QUEUE_ESTIMATED_TIME_ENDPOINT = `${PATIENT_QUEUE_SERVICE_PATH}/api/estimated-time`;

export const config = {
  api: {
    gateway: {
      GATEWAY_AUTHENTICATE_ENDPOINT,
      GATEWAY_REGISTER_ENDPOINT,
      GATEWAY_ACCOUNT_ENDPOINT,
      GATEWAY_USER_ENDPOINT,
    },
    user_profile: {
      USER_SPEEDTEST_API_ENDPOINT,
      USER_DATA_ENDPOINT,
      PROFESSIONAL_USER_PROFILE_ENDPOINT,
      PROFESSIONAL_BS_USER_PROFILE_ENDPOINT,
      PROFESSIONAL_BS_SEARCH_ENDPOINT,
      PROFESSIONAL_BS_PREPAID_HEALTH_ENDPOINT,
      PHARMACIST_USER_PROFILE_ENDPOINT,
      PATIENT_USER_PROFILE_ENDPOINT,
      PATIENT_MEDICATION_ENDPOINT,
      PATIENT_BS_USER_PROFILE_ENDPOINT,
      FAMILY_GROUP_USER_PROFILE_ENDPOINT,
      PATIENT_CHANGE_PLAN_ENDPOINT,
      PACIENT_STUDY_ENDPOINT,
      PACIENT_MEMBER_STUDY_ENDPOINT,
      USERPROFILE_QUEUE_ENDPOINT,
      // PATIENT_MEMBER_USER_PROFILE_ENDPOINT,
    },
    vademecum: {
      VADEMECUM_MEDICATION_ENDPOINT,
      VADEMECUM_MEDICATION_SEARCH_ENDPOINT,
    },
    pharmacy: {
      PHARMACY_BRANCH_OFFICES_PHARMACIES_ENDPOINT,
      PHARMACY_BRANCH_OFFICES_PHARMACIES_SEARCH_ENDPOINT,
      PHARMACY_PURCHASE_ORDERS_ENDPOINT,
      PHARMACY_PURCHASE_ORDERS_SEARCH_ENDPOINT,
      PHARMACY_PRESCRIPTIONS_PURCHARSE_ORDERS_ENDPOINT,
      PHARMACY_PRESCRIPTION_MEDICATIONS_PRESCRIPTIONS_ENDPOINT,
      PHARMACY_EXTRAS_PURCHASE_ORDERS_ENDPOINT,
    },
    consultation: {
      CONSULTATION_ENDPOINT,
      CONSULTATION_ENDPOINT_BUSINESS,
      AGORA_CONSULTATION_ENDPOINT_BUSINESS,
      CONSULTATION_PATIENT_ENDPOINT,
      CONSULTATION_ENDPOINT_BUSINESS_BY_PROFESSIONAL,
      CONSULTATION_RANKING_ENDPOINT,
    },
    appointment: {
      APPOINTMENT_ENDPOINT,
      APPOINTMENT_MY_APPOINTMENTS_ENDPOINT,
    },
    chime: {
      CHIME_JOIN_ENDPOINT,
      CHIME_ATTENDEE_ENDPOINT,
      CHIME_END_ENDPOINT,
    },
    notification: {
      NOTIFICATION_SERVICE_PATH,
      NOTIFICATION_QUANTITY_ENDPOINT,
      NOTIFICATION_SERVICE_ENDPINT,
      NOTIFICATION_TEMPLATES_VIEW_SERVICE_ENDPOINT,
      NOTIFICATION_DELIVERIES_BY_USERLOGIN_SERVICE_ENDPOINT,
    },
    prescription: {
      PRESCRIPTION_PATIENT_ENDPOINT,
    },
    schedule: {
      SCHEDULE_SERVICE_PATH,
      SCHEDULE_AVAILABILITY_ENDPOINT,
    },
    parametrics: {
      PARAMETRICS_SERVICE_PATH,
      PARAMETRICS_SURGERY_ENDPOINT,
      PARAMETRICS_PREEXISTING_DISEASES_ENDPOINT,
      PARAMETRICS_PREPAID_HEALTH_ENDPOINT,
      PARAMETRICS_PREPAID_HEALTH_CATEGORIES_ENDPOINT,
      PARAMETRICS_PREPAID_HEALTH_SEARCH_ENDPOINT,
      PARAMETRICS_PREEXISTING_DISEASES_SEARCH_ENDPOINT,
      PARAMETRICS_SURGERY_SEARCH_ENDPOINT,
      PARAMETRICS_SPECIALITIES_ENDPOINT,
      PARAMETRICS_SPECIALITIES_ALL_ENDPOINT,
      PARAMETRICS_SPECIALITIES_SEARCH_ENDPOINT,
      PARAMETRICS_FINANCER_ENDPOINT,
      PARAMETRICS_FINANCER_PLAN_ENDPOINT,
      PARAMETRICS_PATIENT_FINANCER_PLANS_ENDPOINT,
      PARAMETRICS_LOCATION_ENDPOINT,
      PARAMETRICS_PROVINCE_ENDPOINT,
      PARAMETRICS_LOG_ENDPOINT,
    },
    time_slot: {
      TIME_SLOT_AVAILABILITY_ENDPOINT,
    },
    queue: {
      PATIENT_QUEUE_SERVICE_PATH,
      QUEUE_PATIENTS_QUEUE_ENDPOINT,
      QUEUE_ESTIMATED_TIME_ENDPOINT,
    },
  },
};
