export const userDataMapperRs = (userdata) => {
	return {
		id: userdata.id,
		username: userdata.alias,
		alias: userdata.alias,
		sex_str: userdata.sex,
		first_name: userdata.firstName,
		last_name: userdata.lastName,
		email: userdata.userLogin,
		birth_date: userdata.birthDate,
		phone_number: userdata.phoneNumber
	}
}

export const userDataMapperRq = (data) => {
	return {
		alias: data.alias,
		birthDate: data.birth_date,
		idNumber: data.id_number,
		phoneNumber: data.phone_number,
		sex: data.sex_str,
		userLogin: data.email,
		firstName: data.first_name,
		lastName: data.last_name
	};
};

export const patietDataMapperRs = (patient) => {
	return {
		patient_dto: {
		id: patient.id,
		affiliate_number: patient.affiliateNumber,
		full_profile: patient.fullProfile,
		size: patient.height,
		weight: patient.weight,
		allergies: patient.allergies,
		family_history: patient.familyHistory,
		vaccines: patient.vaccines,
		gynecological_data: patient.gynecologicalData,
		clinicHistoryId: patient.clinicHistoryId,
		}
	}
}

export const patientDataOfFamilyGroupMapperRq = () => {
	return {
		id: null,
		patientMemberId: null,
		creationDate:null,
		planId:null,
		relationShip:null,
		patient:
		  {
		  affiliateNumber: null,
		  allergies: null,
		  familyHistory: null,
		  fullProfile: true,
		  gynecologicalData: null,
		  height: 0,
		  prepaidHealthCategoryId: null,
		  patientFrequentMedications: [
			{
			  frequentMedicationId: 0,
			  patient: null
			}
		  ],
		  patientPreExistingDiseases: [
			{
			  patient: null,
			  preExistingDiseaseId: 0
			}
		  ],
		  patientSurgeries: [
			{
			  patient: null,
			  surgeryId: 0
			}
		  ],
		  userData: {
			addresses: [],
			alias: null,
			birthDate: null,
			idNumber: null,
			phoneNumber: null,
			sex: null,
			userLogin: null
		  },
		  userLogin: null,
		  vaccines: null,
		  weight: 0
		}
	  }
}
