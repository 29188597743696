import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import { WhatsApp } from "@material-ui/icons";
import { Email } from "@material-ui/icons";

const useStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex"
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end"
    },
    messageGreen: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#27ae60",
      color: 'white',
      width: "40%",
      height: "85%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #27ae60",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #27ae60",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #27ae60",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"
      }
    },
    messageBlue: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#018FC7",
      color: 'white',
      width: "40%",
      height: "85%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #018FC7",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #018FC7",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #018FC7",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"
      }
    },
    messageContent: {
      padding: 0,
      margin: 0
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "2px",
      right: "5px"
    },
    orange: {
      backgroundColor: deepOrange[500],
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
    },
    displayName: {
      marginLeft: "20px"
    }
  })
);

export const MessageRightEmail = (props) => {
  const classes = useStyles();
  const message = props.message ? props.message : "";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageBlue}>
        <span className={classes.messageContent}>{message}</span>
        <div className={classes.messageTimeStampRight}>{<div> <Email style={{fontSize: 18}}/> {timestamp} </div>}</div>
      </div>
    </div>
  );
};

export const MessageRightWhatsapp = (props) => {
  const classes = useStyles();
  const message = props.message ? props.message : "";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageGreen}>
        <span className={classes.messageContent}>{message}</span>
        <div className={classes.messageTimeStampRight}>{<div> <WhatsApp style={{fontSize: 18}}/> {timestamp} </div>}</div>
      </div>
    </div>
  );
};