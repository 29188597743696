import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  Tooltip,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import plus from "../../../../../assets/img/plus.svg";
import ListItem from "../../../../../components/ListItem";
import InputField from "../../../../../components/FormFields/InputField";
import { removeIndexFromList } from '../../../../../utils/FormUtil';
import { Formik, useField } from 'formik';
import * as Yup from "yup";
import { getMedicationSearch } from '../../../../../services/medicationService';
import { useStateAuthValue } from '../../../../../context/Auth/AuthState';
import AsyncCreatableSelect from 'react-select/async-creatable';

export default function Prescription({
  formField,
  parentValues,
  hasErrors,
  errors,
  setFieldValue,
  handleDisabled
}) {
  const [{ userToken }] = useStateAuthValue();
  const { diagnosis, medications, other_indications } = formField;
  const [ parentMedications, setParentMedications ] = useState([]);
  const [ medicationValue, setMedicationValue ] = useState(null);
  const [field] = useField("Diagnosis");

  const initialValues = {
    diagnosis: field?.value || '',
    quantity: 1,
    medicationName: '',
    posology: '',
  };
  const MedicationSchema = Yup.object().shape({
    diagnosis: Yup.string().required(diagnosis.requiredErrorMsg).nullable(),
   /* medicationName: Yup.string()
      .min(1, medications.medicationName.requiredErrorMsg)
      .nullable()
      .required(medications.medicationName.requiredErrorMsg)
       .test('unique', medications.uniqueMsg, function (value) {
         return (
          !parentMedications.length ||
          (value && !parentMedications.find((item) => item.name === value))
       );
       })
      ,*/
    posology: Yup.string()
      .required(medications.posology.requiredErrorMsg)
      .nullable(),
    quantity: Yup.number(medications.quantity.numberError)
      .required(medications.quantity.requiredErrorMsg)
      .nullable(),
  });

  const addItem = (values, setField, validateForm) => {
   
    const name = medicationValue.label;
    if (name?.length) {
      if (
        parentMedications.length &&
        parentMedications.find((item) => item.name === name)
      ) {
        return;
      }
      const newList = [
        {
          id: Math.random().toString(), // NOSONAR
          diagnosis: values.diagnosis,
          primary: values.diagnosis,
          name: name,
          quantity: values.quantity,
          posology: values.posology,
        },
        ...parentMedications,
      ];
      setFieldValue(medications.name, newList);
      setParentMedications(newList);
      handleDisabled(false);
      Object.keys(values).forEach((key) => setField(key, initialValues[key]));
      validateForm(initialValues);
      setMedicationValue(null);
    }
  };

  const removeItem = (index) => {
    if (index || index === 0) {
      const newList = removeIndexFromList(index, parentMedications, setParentMedications);
      setFieldValue(medications.name, newList);
      if (!newList.length) {
        handleDisabled(true);
      }
    }
  };

  useEffect(() => {
    handleDisabled(hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]);

  useEffect(() => {
    if (!parentValues.medications) {
      setParentMedications([]);
      setFieldValue(medications.name, []);
    } else {
      setParentMedications(parentValues.medications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);


  const selectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        border: "none",
        borderBottom: "1px solid #6c6c6c",
        boxShadow: state.isFocused,
        background: "transparent",
        borderRadius: "none",
         fontSize: "12px",
        margin: "-6px 0 0 0"
    }), indicatorSeparator: () => ({
        display: "none"
    }), dropdownIndicator: provided => ({
        ...provided, color: "#0c0c0c"
    }), valueContainer: provided => ({
        ...provided, padding: "0"
    }), menuPortal: base => ({ ...base, zIndex: 1301 }),
    menu: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
};

const promiseOptions = inputValue =>
 new Promise(resolve => {
      resolve( inputValue.length > 2 ? getMedicationSearch({ description: inputValue }, userToken) : null);
  });

 const noOptionsMessage = inputValue => {
    if (inputValue && inputValue.inputValue.length < 3) {
      return 'Debe ingresar al menos 3 caractes';
    }else{
      return 'No se encontraron medicamentos';
    }
   
  };


  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="h1-head">Recetas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={initialValues}
            validationSchema={MedicationSchema}
            validateOnMount
            enableReinitialize
          >
            {({ values, isValid, setFieldValue: setField, validateForm, validateField }) => {
              if (!values?.diagnosis && parentValues?.diagnosis) {
                setField('diagnosis', parentValues.diagnosis);
                values.diagnosis = parentValues.diagnosis;
                validateField('diagnosis');
             //   validateField('medicationName');
              }
              
              return (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <InputField
                      onKeyPress={e => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      name="diagnosis"
                      label="Diagnostico *"
                      className="field"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} style={{marginRight: "15px"}}>
                    <InputField
                      onKeyPress={e => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      name="quantity"
                      label="Cantidad *"
                      className="field"
                    />
                  </Grid>
                  <Grid item xs={10}>
                 
                   <label  className="label-medication">Medicamento *</label>
                     {                           
                        <AsyncCreatableSelect 
                          cacheOptions 
                          defaultOptions
                          loadOptions={promiseOptions}
                          onChange={e => {
                            setField('medicationName', e?e.label:null);
                            values.medicationName =  e?e.label:null;
                            //validateField('medicationName');
                            setMedicationValue(e);
                          } }
                           value={medicationValue}
                           placeholder={"Buscar Medicamento"}
                           styles={selectCustomStyles}
                           noOptionsMessage={noOptionsMessage}
                           isClearable
                           />
                         
                    }  
                  </Grid>
                 
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <InputField
                      onKeyPress={e => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      name="posology"
                      label="Posología *"
                      className="field"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Agregar medicamento">
                      <Button
                        width={40}
                        type="button"
                        fullWidth={false}
                        disabled={isValid && medicationValue? false:true}
                        className="plus-button"
                        onClick={() => addItem(values, setField, validateForm)}
                      >
                        <img src={plus} alt="plus" />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
                <div className="pt-10">
                  <ListItem
                    data={parentMedications}
                    removeItem={removeItem}
                    removeTooltip="Eliminar medicamento"
                    noDataMessage=""
                    required={true}
                    errors={errors}
                    label={medications.name}
                    className="field"
                    displayKeys={["name", "posology"]}
                  />
                </div>
              </>
            )
          }
            }
          </Formik>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="h1-head" component={"span"}>
            Otras Indicaciones
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"}>
            <InputField
              id="other_indications"
              maxLength={2500}
              rows={other_indications.rows}
              name={other_indications.name}
              label={other_indications.label}
              placeholder={other_indications.placeholder}
              change={(e) => handleDisabled(e.target.value === '')}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {!parentMedications?.length && !!errors && (
        <FormControl error={!!errors}>
          <FormHelperText>{errors}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
}
