import React, { useState } from "react";
import { Button, Dialog, DialogContent, IconButton, Tooltip } from "@material-ui/core";
import HistoryClinicDialog from "../../../../historyClinic/HistoryClinicDetail/historyClinic";
import CloseIcon from "@material-ui/icons/Close";

const Patient = ({ patient }) => {
  const [open, setOpen] = useState(false);

  const openHistoryClinic = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="accordion-patient">
      <div className="detail-wrapper">
        <div className="detail">
          <span className="data">
            Cobertura: {`${patient?.patient_dto?.prepaid_health_category?.prepaid_health?.name || ''} ${patient?.patient_dto?.prepaid_health_category?.name || '-'}`}
          </span>
        </div>
        <div className="detail">
          <span className="data">
            N° Afiliado: {patient?.patient_dto?.affiliate_number || '-'}
          </span>
        </div>
      </div>
      <Button className="button-hc" onClick={openHistoryClinic}>
        Historia Clínica
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <div className="close">
          <Tooltip title="Cerrar">
            <IconButton onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent>
          <HistoryClinicDialog
            email={patient?.familymember || ''}
            showHeader={true}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Patient;
