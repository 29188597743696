export const addressMapperRq = (a) => {
	return {
		addressType: "PRIMARY",
		betweenStreets: a.between_streets || null,
		city: a.city || null,
		country: a.country || null,
		floor: a.floor || null,
		number: a.number || null,
		province: a.province || null,
		street: a.street || null,
		zipCode: a.postal_code || null,
		apartment: a.number_department || null
	};
};

export const addressMapperRs = (ad) => {
	return ad.map(a => {
		return {
			id: a.id,
			between_streets: a.betweenStreets,
			city: a.city,
			country: a.country,
			floor: a.floor,
			number: a.number,
			province: a.province,
			street: a.street,
			postal_code: a.zipCode,
			number_department: a.apartment
		};
	});
};
