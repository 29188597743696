import React, { useState, useEffect } from 'react';
import { Button, Icon, List, IconButton, Tooltip } from '@material-ui/core';
import RefreshRounded from '@material-ui/icons/RefreshRounded';
import HeaderImage from '../../../assets/img/agenda.svg';
import AppointmentStatus from '../../../models/enums/AppointmentStatus';
import Appointment from '../../../models/Appointment';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import {
  getAppointments,
  putAppointment,
} from '../../../services/appointmentService';
import { useStateAuthValue } from '../../../context/Auth/AuthState';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { showToast, ToastType } from '../../../utils/ToastUtil';
import { removeIndexFromList } from '../../../utils/FormUtil';
import { useToast } from '../../../context/Toast/ToastProvider';
import TurnItem from './Item';
import { formatDate } from '../../../utils/DateUtil';
import { useHistory } from 'react-router-dom';

const TurnList = () => {
  const history = useHistory()
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const fetchAppointments = (isMounted) => {
    if (isMounted) {
      setLoading(true);
    }
    const errorMessage = "Error al Buscar turnos programados";
    getAppointments({ statuses: [AppointmentStatus.PROGRAMMED], dateFrom: [formatDate(new Date())] }, userToken,
      (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
      .then((response) => {
        if (isMounted && response.status === "OK") {
          const appointmentDtos = response.data.map((appointment) =>
            Appointment.AppointmentDto(
              appointment.id,
              appointment.appointment_date,
              appointment.status,
              appointment.patient_dto,
              appointment.professional_dto
            )
          );
          setAppointments(appointmentDtos.sort((a, b) =>
            a.appointment_date > b.appointment_date ? 1 : -1,
          ));
        } else {
          showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
        }
      })
      .catch(() =>
        showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    let isMounted = true;
    if (userToken) {
      fetchAppointments(isMounted);
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const handleClose = (confirmResponse) => {
    setOpenModal(false);
    if (confirmResponse) {
      const errorMessage = "Error al Cancelar el turno";
      setLoading(true);
      putAppointment(
        selectedAppointment?.id,
        { status: AppointmentStatus.CANCELLED },
        userToken,
        (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR)
      )
        .then((response) => {
         if (response.status === "ok") {
            removeIndexFromList(selectedAppointment.index, appointments, setAppointments);
         } else {
            showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
         }
          setSelectedAppointment(null);
        })
        .catch(() =>
          showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR)
        )
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="turn-list">
      <div className="header">
        <img src={HeaderImage} alt="Próximos Turnos" />
        <h2>Próximos Turnos</h2>
        <Tooltip title="Actualizar Próximos Turnos" component="span">
            <IconButton disabled={loading} onClick={() => {
                const consult = localStorage.getItem("consult");
              if(consult !== "true") fetchAppointments(true)
              }}><RefreshRounded/></IconButton>
        </Tooltip>
      </div>
      <div className="turn-list__list">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <List>
            {appointments.map((item, index) => {
              return (
                <TurnItem
                  item={item}
                  key={item.id}
                  selected={index === 0}
                  index={index}
                  showCancel={() => {
                     item.index = index;
                     setSelectedAppointment(item);
                     setOpenModal(true);
                  }}
                />
              );
            })}
          </List>
        )}
      </div>
      <ConfirmationModal
        open={openModal}
        handleClose={handleClose}
        title="¿Desea cancelar el turno?"
        confirmButton="Confirmar"
        maxWidth="sm"
        cancelButton="Volver"
      />
      <div className="button-wrapper">
        <Button
          className="button-primary"
          variant="contained"
          endIcon={<Icon>arrow_forward</Icon>}
          onClick = {()=>{
            const consult = localStorage.getItem("consult");
            if(consult !== "true") history.push("/profesional/agenda-de-turnos")}}
        >
          Ver Todos
        </Button>
      </div>
    </div>
  );
};
export default TurnList;
