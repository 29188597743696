import Wrapper from "./wrapper";
import axios from "axios";
import { config } from "../config";
import PatientWrapper from "./patient.wrapper";
import ParametricsWrapper from "./parametrics.wrapper";

class PatientQueueWrapper extends Wrapper {
	constructor() {
		super();
	}

	async getPatientsQueue(jwtToken, specialtyId) {
		try {
			const response = await axios.get(
				`${config.api.queue.QUEUE_PATIENTS_QUEUE_ENDPOINT}/${specialtyId}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientsQueue: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}
	}

	async getEstimatedTime(jwtToken, specialtyId, queueId) {
		try {
			const response = await axios.get(
				`${config.api.queue.QUEUE_ESTIMATED_TIME_ENDPOINT}/${specialtyId}/${queueId}`,
				{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getEstimatedTime: ${error.message}`;
			console.log(error);
			return 0;
			// if(error.response.status == 404) {
			// 	return 0;
			// } else throw error;
		}
	}


}

export default PatientQueueWrapper;
