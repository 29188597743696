
class Wrapper {
    constructor() {
        this.concatNotNullParam = (url, params) => {
            if (params) {
                for (const key in params) {
                    const param = params[key];
                    if (param === 0 || (param && param !== undefined && param !== '' &&
                        (!Array.isArray(param) || param.length) )) {
                        if (url.indexOf('?') > -1) {
                        url += `&${key}=${param}`;
                        } else {
                        url += `?${key}=${param}`;
                        }
                    }
                }
                return url;
            } else {
                return url;
            }
        };

        this. commonHeaders = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        this. commonFileHeaders = {
            "Content-Type": "multipart/form-data",
        };
    }
}

export default Wrapper;
