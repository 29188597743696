import Wrapper from "./wrapper";
import axios from "axios";
import {config} from "../config";

class PrepaidHealthWrapper extends Wrapper {
	constructor() {
		super();
	}

	async getPrepaidHealth(jwtToken) {
		try {
			const response = await axios.get(
				config.api.parametrics.PARAMETRICS_PREPAID_HEALTH_ENDPOINT,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status:"OK",data:response.data};
		} catch (error) {
			error.message = `Error mdw getPrepaidHealth: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	}

	async getPrepaidHealthCategory(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_PREPAID_HEALTH_CATEGORIES_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status:"OK",data:response.data};
		} catch (error) {
			error.message = `Error mdw getPrepaidHealthCategory: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	}

	async getPrepaidHealthCategoryByPrepaidHealth(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_PREPAID_HEALTH_CATEGORIES_ENDPOINT}/prepaid/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status:"OK",data:response.data};
		} catch (error) {
			error.message = `Error mdw getPrepaidHealthCategoryByPrepaidHealth: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	}
}

export default PrepaidHealthWrapper;
