import Wrapper from "./wrapper";
import axios from "axios";
import {config} from "../config";
import PrepaidHealthWrapper from "./prepaid.health.wrapper";

class UserWrapper extends Wrapper{
	constructor() {
		super();
	}

	async userRegister(body) {
		try {
			const response = await axios.post(config.api.gateway.GATEWAY_REGISTER_ENDPOINT, {
				login: body.email,
				email: body.email,
				password: body.password,
				idNumber: body.id_number,
				authorities: [body.active_role]
			});

			return response;
		} catch (error) {
			error.message = `Error creating user: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};
	async getPreexistingDisease(jwtToken){
		try {
			const response = await axios.get(
				config.api.parametrics.PARAMETRICS_PREEXISTING_DISEASES_ENDPOINT,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response;
		} catch (error) {
			error.message = `Error mdw getPreexistingDisease: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};
	async getSurgeries(jwtToken){
		try {
			const response = await axios.get(
				config.api.parametrics.PARAMETRICS_SURGERY_ENDPOINT,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response;
		} catch (error) {
			error.message = `Error mdw getSurgeries: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};
	async getFrequentMedication(jwtToken){
		try {
			const response = await axios.get(
				config.api.vademecum.VADEMECUM_MEDICATION_ENDPOINT,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);

			return response.data.content.map(m=>{return{id:m.id, name:m.description}});
		} catch (error) {
			error.message = `Error mdw getFrequentMedication: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	// ENDPOINT DEPRECADO
	async getAttentionTypes(jwtToken) {
		return null;
	};
	async getSpecialties(jwtToken){
		try {
			const response = await axios.get(
				config.api.parametrics.PARAMETRICS_SPECIALITIES_ENDPOINT,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response;
		} catch (error) {
			error.message = `Error mdw getSpecialties: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getSpecialtiesAll(jwtToken){
		try {
			const response = await axios.get(
				config.api.parametrics.PARAMETRICS_SPECIALITIES_ALL_ENDPOINT,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response;
		} catch (error) {
			error.message = `Error mdw getSpecialties: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	// ENDPOINT DEPRECADO
	async getSpecialtiesProfessional(jwtToken){
		return null
	};
	async getSpeciality(jwtToken, params){
		try {
			const response = await axios.get(
				this.concatNotNullParam(config.api.parametrics.PARAMETRICS_SPECIALITIES_ENDPOINT, params),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getSpeciality: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};
	async getPrepaids(jwtToken){
		const phw = new PrepaidHealthWrapper();
		return await phw.getPrepaidHealth(jwtToken);
	};

	// ENDPOINT DEPRECADO
	async getPrepaid(jwtToken, params){
		return null;
	};

	// ENDPOINT DEPRECADO
	async getProviders(jwtToken){
		return null;
	};

	// ENDPOINT DEPRECADO
	async getProvider(jwtToken,params){
		return null
	};
}

export default UserWrapper;
