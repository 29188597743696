import Wrapper from "./wrapper";
import axios from "axios";
import {config} from "../config";

class ParametricsWrapper extends Wrapper {
	constructor() {
		super();
	}

	async searchPreexistingDiseases(jwtToken, diseases) {
		if (diseases.length > 0) {
			const ids = diseases.map(d => {
				return d.preExistingDiseaseId
			});
			try {
				const response = await axios.get(
					`${config.api.parametrics.PARAMETRICS_PREEXISTING_DISEASES_SEARCH_ENDPOINT}/${ids.toString()}`,
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
				diseases = diseases.map(d => {
					return {
						id: d.id,
						preExistingDiseaseId: d.preExistingDiseaseId,
						name: response.data.filter(f => f.id === d.preExistingDiseaseId)[0].name
					}
				});
			} catch (e) {
				console.log(e);
				if(e.response.status == 404) {
					return [];
					} else throw e;
			}
		}
		return diseases;
	}

	async searchSurgeries(jwtToken, surgeries) {
		if (surgeries.length > 0) {
			const ids = surgeries.map(s => {
				return s.surgeryId
			});
			try {
				const response = await axios.get(
					`${config.api.parametrics.PARAMETRICS_SURGERY_SEARCH_ENDPOINT}/${ids.toString()}`,
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
				surgeries = surgeries.map(s => {
					return {
						id: s.id,
						surgeryId: s.surgeryId,
						name: response.data.filter(f => f.id === s.surgeryId)[0].name
					}
				});
			} catch (e) {
				console.log(e);
				if(e.response.status == 404) {
					return [];
					} else throw e;
			}				
		}
		return surgeries;
	}

	async searchMedications(jwtToken, medications) {
		if (medications.length > 0) {
			const ids = medications.map(s => {
				return s.frequentMedicationId
			});
			try {
				const response = await axios.get(
					`${config.api.vademecum.VADEMECUM_MEDICATION_SEARCH_ENDPOINT}/${ids.toString()}`,
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
				medications = medications.map(s => {
					return {
						id: s.id,
						frequentMedicationId: s.frequentMedicationId,
						name: response.data.filter(f => f.id === s.frequentMedicationId)[0].description
					}
				});
			} catch (e) {
				console.log(e);
				if(e.response.status == 404) {
					return [];
					} else throw e;
			}	
		}
		return medications;
	}

	async searchPrepaidHealths(jwtToken, prepaidHealthList) {
		if (prepaidHealthList.length > 0) {
			const ids = prepaidHealthList.map(s => {
				return s.prepaidHealthId
			});
			try {
				const response = await axios.get(
					`${config.api.parametrics.PARAMETRICS_PREPAID_HEALTH_SEARCH_ENDPOINT}/${ids.toString()}`,
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
				prepaidHealthList = prepaidHealthList.map(s => {
					return {
						id: s.id,
						prepaidHealthId: s.prepaidHealthId,
						name: response.data.filter(f => f.id === s.prepaidHealthId)[0].name
					}
				});
			} catch (e) {
				console.log(e);
				if(e.response.status == 404) {
					return [];
					} else throw e;
			}				
		}
		return prepaidHealthList;
	}

	async searchSpecialities(jwtToken, specialities) {
		if (specialities.length > 0) {
			const ids = specialities.map(s => {
				return s.specialityId
			});
			try {
				const response = await axios.get(
					`${config.api.parametrics.PARAMETRICS_SPECIALITIES_SEARCH_ENDPOINT}/${ids.toString()}`,
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
				specialities = specialities.map(s => {
					return {
						id: s.id,
						specialityId: s.specialityId,
						name: response.data.filter(f => f.id === s.specialityId)[0].name
					}
				});
			} catch (e) {
				console.log(e);
				if(e.response.status == 404) {
					return [];
					} else throw e;
			}				
		}
		return specialities;
	}

	async getSpecialty(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_SPECIALITIES_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (e) {
			error.message = `Error mdw getSpecialty: ${e.message}`;
			console.log(e);
			if(e.response.status == 404) {
				return [];
			} else throw e;
		}				
	}

	async getLocation(jwtToken) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_LOCATION_ENDPOINT}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (e) {
			error.message = `Error mdw getLocation: ${e.message}`;
			console.log(e);
			if(e.response.status == 404) {
				return [];
			} else throw e;
		}				
	}

	async getLocationByProvince(jwtToken, provinceid) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_LOCATION_ENDPOINT}/province/${provinceid}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (e) {
			error.message = `Error mdw getLocationProvince: ${e.message}`;
			console.log(e);
			if(e.response.status == 404) {
				return [];
			} else throw e;
		}
	}

	async getProvince(jwtToken) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_PROVINCE_ENDPOINT}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (e) {
			error.message = `Error mdw getProvince: ${e.message}`;
			console.log(e);
			if(e.response.status == 404) {
				return [];
			} else throw e;
		}				
	}

	async getPrepaidHealth(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_PREPAID_HEALTH_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPrepaidHealth: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}			
	}

	async getPlanByPatient(jwtToken, patientid) {
		try {
			const responsePatientId = await axios.get(
				`${config.api.parametrics.PARAMETRICS_PATIENT_FINANCER_PLANS_ENDPOINT}/patient/${patientid}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			const responseId = await axios.get(
				`${config.api.parametrics.PARAMETRICS_FINANCER_PLAN_ENDPOINT}/${responsePatientId.data.financerPlanId}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			var returnData = {
				affiliateNumber: responsePatientId.data.affiliateNumber,
				...responseId.data
			};
			return returnData;
		} catch (error) {
			error.message = `Error mdw getPlanByPatient: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}
	}

	async getPlansByFinancer(jwtToken, financerId) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_FINANCER_PLAN_ENDPOINT}/financer/${financerId}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status:"OK",data:response.data};
		} catch (error) {
			error.message = `Error mdw getPlansByFinancer: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}			
	}

	async getPlanById(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_FINANCER_PLAN_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status:"OK",data:response.data};
		} catch (error) {
			error.message = `Error mdw getPlanById: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}			
	}

	async getFinancers(jwtToken) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_FINANCER_ENDPOINT}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status:"OK",data:response.data};
		} catch (error) {
			error.message = `Error mdw getFinancers: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}			
	}

	async getFinancersById(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.parametrics.PARAMETRICS_FINANCER_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status:"OK",data:response.data};
		} catch (error) {
			error.message = `Error mdw getFinancers: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}
	}

	async getPatientFrequentMedications(jwtToken, id) {
		try {
			const response = await axios.get(
				this.concatNotNullParam(config.api.user_profile.PATIENT_MEDICATION_ENDPOINT + '/' + id),
				{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
			);
			return { data: response.data };
		} catch (error) {
			error.message = `Error mdw getPatientFrequentMedications: ${error.message}`;
			console.log(error);
			if (error.response.status == 404) {
				return [];
			} else throw error;
		}
	};
	async postProfessionalNotification(jwtToken, entity, event ) {
        try {
			const data = {
				"entityId" : entity.id,
				"event" : event,
				"entity": { userLogin: entity.email, firstName: entity.first_name, lastName: entity.last_name, phoneNumber: entity.phone_number },
				"recipients" : [ entity.email ]
			}
			const responseNotification = axios.post(
				config.api.notification.NOTIFICATION_SERVICE_ENDPINT, data,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return responseNotification;
        } catch (error) {
            error.message = `Error mdw postNotification: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
    }

	async eventLog(jwtToken, source, type, object) {
		try {
			if (source === 'login' && type === 'ROLE_PROFESSIONAL' && object.data) {
				this.postProfessionalNotification(jwtToken, object.data, 'PROFESSIONAL_CONNECTED')
			}
			const response = await axios.post(config.api.parametrics.PARAMETRICS_LOG_ENDPOINT, {
				source: source,
				type: type,
				object: JSON.stringify(object)
			},
			{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}});

			return response;
		} catch (error) {
			error.message = `Error creating user: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}
	};

}

export default ParametricsWrapper;
