import Wrapper from "./wrapper";
import axios from "axios";
import { config } from "../config";
import patientSchemaRequest from "../contracts/patient/patient.schema.rq.json";
import patientOldSchemaResponse from "../contracts/patient/patient.old.schema.rs.json";
import patientOfFamilyGroupOldSchemaResponse from "../contracts/patient.family.group/patient.family.group.old.schema.rs.json";
import patientOfFamilyGroupSchemaResponse from "../contracts/patient.family.group/patient.family.group.schema.rq.json";

import decode from "jwt-decode";
import PrepaidHealthWrapper from "./prepaid.health.wrapper";
import ParametricsWrapper from "./parametrics.wrapper";
import { addressMapperRq, addressMapperRs } from "../commons/address.mapper";
import { userDataMapperRq, userDataMapperRs, patietDataMapperRs, patientDataOfFamilyGroupMapperRq } from "../commons/user.data.mapper";
import UserProfileWrapper from "./user.profile.wrapper";

function generateUUID() {
	function separateUUID() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	return separateUUID() + separateUUID() + '-' + 
		   separateUUID() + '-' + 
		   separateUUID() + '-' + 
		   separateUUID() + '-' + 
		   separateUUID() + separateUUID() + separateUUID();
}

const patientMapperRq = (patient) => {
	if (patient.patient_dto.id)
		patientSchemaRequest.id = patient.patient_dto.id;
	patientSchemaRequest.allergies = patient.patient_dto.allergies;
	patientSchemaRequest.familyHistory = patient.patient_dto.family_history;
	patientSchemaRequest.gynecologicalData = patient.patient_dto.gynecological_data;
	patientSchemaRequest.userData = userDataMapperRq(patient);
	if (patient.id)
		patientSchemaRequest.userData.id = patient.id;
	patientSchemaRequest.userData.addresses = [];
	patientSchemaRequest.userData.addresses.push(addressMapperRq(patient.address_dto));
	if (patient.address_dto.id)
		patientSchemaRequest.userData.addresses[0].id = patient.address_dto.id;
	patientSchemaRequest.userLogin = patientSchemaRequest.userData.userLogin;
	patientSchemaRequest.vaccines = patient.patient_dto.vaccines;
	patientSchemaRequest.weight = patient.patient_dto.weight;
	patientSchemaRequest.height = patient.patient_dto.size;
	patientSchemaRequest.affiliateNumber = patient.patient_dto.affiliate_number;
	patientSchemaRequest.prepaidHealthCategoryId = patient.patient_dto.prepaid_health_category.id || null;
	patientSchemaRequest.patientFrequentMedications = patient.patient_dto.frequent_medication.map(fm => {
		let obj = {frequentMedicationId: fm.name};
		if (fm.frequentMedicationId) obj.name = fm.frequentMedicationId;
		return obj;
	});
	patientSchemaRequest.patientPreExistingDiseases = patient.patient_dto.pre_existing_disease.map(pd => {
		let obj = { preExistingDiseaseId: pd.id };
		if (pd.preExistingDiseaseId) obj.id = pd.preExistingDiseaseId;
		return obj;
	});
	patientSchemaRequest.patientSurgeries = patient.patient_dto.surgeries.map(sg => {
		let obj = { surgeryId: sg.id };
		if (sg.surgeryId) obj.id = sg.surgeryId;
		return obj;
	});
	patientSchemaRequest.familyGroup = [];
	patient.patient_dto.family_group.map(element => {
		patientSchemaRequest.familyGroup.push(patientOfFamilyGroupMapperRq(element));
	});
	return patientSchemaRequest;
};

const patientOfFamilyGroupMapperRq = (patient) => {
	const patientOfFamilyGroupSchemaRequest = JSON.parse(JSON.stringify(patientOfFamilyGroupSchemaResponse));
	if (patient.patient_dto.id)
		patientOfFamilyGroupSchemaRequest.patient.id = patient.patient_dto.id;
	patientOfFamilyGroupSchemaRequest.patient.allergies = patient.patient_dto.allergies;
	patientOfFamilyGroupSchemaRequest.patient.familyHistory = patient.patient_dto.family_history;
	patientOfFamilyGroupSchemaRequest.patient.gynecologicalData = patient.patient_dto.gynecological_data;
	patientOfFamilyGroupSchemaRequest.patient.userData = userDataMapperRq(patient);
	if (patient.id)
		patientOfFamilyGroupSchemaRequest.patient.userData.id = patient.id;
	patientOfFamilyGroupSchemaRequest.patient.userData.addresses = [];
	patientOfFamilyGroupSchemaRequest.patient.userData.addresses.push(addressMapperRq(patient.address_dto));
	if (patient.address_dto.id)
		patientOfFamilyGroupSchemaRequest.patient.userData.addresses[0].id = patient.address_dto.id;
	patientOfFamilyGroupSchemaRequest.patient.userLogin = patientOfFamilyGroupSchemaRequest.patient.userData.userLogin;
	patientOfFamilyGroupSchemaRequest.patient.vaccines = patient.patient_dto.vaccines;
	patientOfFamilyGroupSchemaRequest.patient.weight = patient.patient_dto.weight;
	patientOfFamilyGroupSchemaRequest.patient.height = patient.patient_dto.size;
	patientOfFamilyGroupSchemaRequest.patient.affiliateNumber = patient.patient_dto.affiliate_number;
	patientOfFamilyGroupSchemaRequest.patient.prepaidHealthCategoryId = patient.patient_dto.prepaid_health_category.id || null;
	patientOfFamilyGroupSchemaRequest.patient.patientFrequentMedications = patient.patient_dto.frequent_medication.map(fm => {
		let obj = { frequentMedicationId: fm.name };
		if (fm.frequentMedicationId) obj.name = fm.frequentMedicationId;
		return obj;
	});
	patientOfFamilyGroupSchemaRequest.patient.patientPreExistingDiseases = patient.patient_dto.pre_existing_disease.map(pd => {
		let obj = { preExistingDiseaseId: pd.id };
		if (pd.preExistingDiseaseId) obj.id = pd.preExistingDiseaseId;
		return obj;
	});
	patientOfFamilyGroupSchemaRequest.patient.patientSurgeries = patient.patient_dto.surgeries.map(sg => {
		let obj = { surgeryId: sg.id };
		if (sg.surgeryId) obj.id = sg.surgeryId;
		return obj;
	});
	patientOfFamilyGroupSchemaRequest.patientMemberId = patient.patient_dto.id;
	patientOfFamilyGroupSchemaRequest.creationDate = null;
	patientOfFamilyGroupSchemaRequest.planId = null;
	patientOfFamilyGroupSchemaRequest.relationShip = patient.relationship_to_titular.value ?patient.relationship_to_titular.value : patient.relationship_to_titular;

	return patientOfFamilyGroupSchemaRequest;
};

let patientOfFamilyGroupMapperRs = (patient) => {

	let pFG = { data: {} };

	pFG.data = {
		...patientOfFamilyGroupOldSchemaResponse.data,
		...userDataMapperRs(patient.userData),
		...patietDataMapperRs(patient),
	};

	return pFG;
};

const patientMapperRs = (patient) => {
	patientOldSchemaResponse.data = {
		...patientOldSchemaResponse.data,
		...userDataMapperRs(patient.userData)
	};
	// patient dto
	patientOldSchemaResponse.data.patient_dto.id = patient.id;
	patientOldSchemaResponse.data.patient_dto.affiliate_number = patient.affiliateNumber;
	patientOldSchemaResponse.data.patient_dto.full_profile = patient.fullProfile;
	patientOldSchemaResponse.data.patient_dto.size = patient.height;
	patientOldSchemaResponse.data.patient_dto.weight = patient.weight;
	patientOldSchemaResponse.data.patient_dto.allergies = patient.allergies;
	patientOldSchemaResponse.data.patient_dto.family_history = patient.familyHistory;
	patientOldSchemaResponse.data.patient_dto.vaccines = patient.vaccines;
	patientOldSchemaResponse.data.patient_dto.gynecological_data = patient.gynecologicalData;
	patientOldSchemaResponse.data.patient_dto.clinicHistoryId = patient.clinicHistoryId;

	return patientOldSchemaResponse;
};

const familyGroupIdMapperRs = (s) => {
	return s.map(sg => {
		return `${sg.patientMemberId}`
	});
};

const surgeriesIdMapperRs = (s) => {
	return s.map(sg => {
		return `${sg.surgeryId}@${sg.name}`
	});
};

const surgeriesMapperRs = (s) => {
	return s.map(sg => {
		return { id: sg.surgeryId, surgeryId: sg.id, name: sg.name };
	});
};

const frequentMedicationsIdMapperRs = (m) => {
	return m.map(fm => {
		return `${generateUUID()}@${fm.frequentMedicationId}`
	});
};

const frequentMedicationsMapperRs = (m) => {
	return m.map(fm => {
		return { id: generateUUID(), frequentMedicationId: generateUUID(), name: fm.frequentMedicationId };
	});
};

const preexistingDiseasesIdMapperRs = (p) => {
	return p.map(pd => {
		return `${pd.preExistingDiseaseId}@${pd.name}`
	});
};

const preexistingDiseasesMapperRs = (p) => {
	return p.map(pd => {
		return { id: pd.preExistingDiseaseId, preExistingDiseaseId: pd.id, name: pd.name };
	});
};
const getProfile = async (jwtToken, response, decodedToken) => {
	const prw = new ParametricsWrapper();
	let patient = patientMapperRs(response.data);
	patient.data.id_number = response.data.userData.idNumber ?response.data.userData.idNumber : '';
	patient.data.id_number = (!patient.data.id_number || patient.data.id_number === '') && response.data.idNumber ?response.data.idNumber : patient.data.id_number;
	patient.data.id_number = (!patient.data.id_number || patient.data.id_number === '') && decodedToken.authid ?decodedToken.authid : patient.data.id_number;
	if (response.data.id){
		const mdctns = await prw.getPatientFrequentMedications(jwtToken, response.data.id);
		patient.data.patient_dto.ids_frequent_medication = frequentMedicationsIdMapperRs(mdctns.data);
		patient.data.patient_dto.frequent_medication = frequentMedicationsMapperRs(mdctns.data);	
	}
	const srgrs = await prw.searchSurgeries(jwtToken, response.data.patientSurgeries);
	patient.data.patient_dto.ids_surgery = surgeriesIdMapperRs(srgrs);
	patient.data.patient_dto.surgeries = surgeriesMapperRs(srgrs);
	const ppw = new PatientWrapper();
	patient.data.patient_dto.family_group = [];
	for (const e of response.data.familyGroup) {
		const pM = await ppw.getPatientOfFamilyGroupProfile(jwtToken, e.patientMemberId);
		patient.data.patient_dto.family_group.push({
			patientMemberId: e.patientMemberId,
			creationDate: e.creationDate,
			...pM.data,
			planId: e.planId,
			relationship_to_titular: e.relationShip
		});
	}
	const prxstngDs = await prw.searchPreexistingDiseases(jwtToken, response.data.patientPreExistingDiseases);
	patient.data.patient_dto.ids_pre_existing_disease = preexistingDiseasesIdMapperRs(prxstngDs);
	patient.data.patient_dto.pre_existing_disease = preexistingDiseasesMapperRs(prxstngDs);
	patient.data.patient_dto.prepaid_health_category = null;
	if (response.data.prepaidHealthCategoryId !== null) {
		const rsPHW = await new PrepaidHealthWrapper().getPrepaidHealthCategory(jwtToken, response.data.prepaidHealthCategoryId);
		patient.data.patient_dto.prepaid_health_category = rsPHW.data;
		patient.data.patient_dto.prepaid_health_category.prepaid_health = patient.data.patient_dto.prepaid_health_category.prepaidHealth;
		delete patient.data.patient_dto.prepaid_health_category.prepaidHealth;
	}
	patient.data.address_dto = addressMapperRs(response.data.userData.addresses)[0];
	const upw = new UserProfileWrapper();
	patient.data.patient_dto.study = await upw.getPatientStudy(jwtToken, patient.data.email !== null ?patient.data.email : decodedToken.sub !== null ?decodedToken.sub : '');
	const aid = await upw.getUserId(jwtToken, decodedToken.sub !== null ?decodedToken.sub : (patient.data.email !== null ?patient.data.email : ''));
	patient.data.patient_dto.auth_id = aid.id;
	return patient;
};

const getProfileMember = async (jwtToken, response, decodedToken) => {
	const prw = new ParametricsWrapper();
	const patient = patientOfFamilyGroupMapperRs(response.data);
	patient.data.id_number = response.data.userData.idNumber ?response.data.userData.idNumber : '';
	patient.data.id_number = (!patient.data.id_number || patient.data.id_number === '') && response.data.idNumber ?response.data.idNumber : patient.data.id_number;
	patient.data.id_number = (!patient.data.id_number || patient.data.id_number === '') && decodedToken.authid ?decodedToken.authid : patient.data.id_number;
	if (response.data.id){
		const mdctns = await prw.getPatientFrequentMedications(jwtToken, response.data.id);
		patient.data.patient_dto.ids_frequent_medication = frequentMedicationsIdMapperRs(mdctns.data);
		patient.data.patient_dto.frequent_medication = frequentMedicationsMapperRs(mdctns.data);
	}
	const srgrs = await prw.searchSurgeries(jwtToken, response.data.patientSurgeries);
	patient.data.patient_dto.ids_surgery = surgeriesIdMapperRs(srgrs);
	patient.data.patient_dto.surgeries = surgeriesMapperRs(srgrs);
	patient.data.patient_dto.family_group = [];
	const prxstngDs = await prw.searchPreexistingDiseases(jwtToken, response.data.patientPreExistingDiseases);
	patient.data.patient_dto.ids_pre_existing_disease = preexistingDiseasesIdMapperRs(prxstngDs);
	patient.data.patient_dto.pre_existing_disease = preexistingDiseasesMapperRs(prxstngDs);
	patient.data.patient_dto.prepaid_health_category = null;
	if (response.data.prepaidHealthCategoryId !== null) {
		const rsPHW = await new PrepaidHealthWrapper().getPrepaidHealthCategory(jwtToken, response.data.prepaidHealthCategoryId);
		patient.data.patient_dto.prepaid_health_category = rsPHW.data;
		patient.data.patient_dto.prepaid_health_category.prepaid_health = patient.data.patient_dto.prepaid_health_category.prepaidHealth;
		delete patient.data.patient_dto.prepaid_health_category.prepaidHealth;
	}
	patient.data.address_dto = addressMapperRs(response.data.userData.addresses)[0];
	const upw = new UserProfileWrapper();
	let idNumberMember = response.data.userLogin ?response.data.userLogin.split("#")[1] : ''
	let userLoginMember = response.data.userLogin ?response.data.userLogin.split("#")[0] : ''
	patient.data.patient_dto.study = await upw.getPatientMemberStudy(jwtToken, userLoginMember, idNumberMember);
	const aid = await upw.getUserId(jwtToken, decodedToken.sub !== null ?decodedToken.sub : (patient.data.email !== null ?patient.data.email : ''));
	patient.data.patient_dto.auth_id = aid.id;
	return patient;
};


class PatientWrapper extends Wrapper {
	constructor() {
		super();
	}

	async patientProfile(jwtToken, body) {
		try {
			const decodedToken = decode(jwtToken);
			const upw = new UserProfileWrapper();
			let response = {};
			let responsePatientStudy = {};
			let responsePatienMembertStudy = {};

			if (body.patient_dto.id) {
				response = await axios.put(
					`${config.api.user_profile.PATIENT_USER_PROFILE_ENDPOINT}/${body.email}`,
					patientMapperRq(body),
					{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
				);
				responsePatientStudy = await upw.putPatientStudy(jwtToken, body.email, body.patient_dto.study);
				if (body.patient_dto.family_group.length !== 0){
					body.patient_dto.family_group.map(async (element) => {
						responsePatienMembertStudy = await upw.putPatientMemberStudy(jwtToken, body.email, element.patient_dto.study, element.id_number ?element.id_number : '');
					})
				}
			} else {
				response = await axios.post(
					config.api.user_profile.PATIENT_USER_PROFILE_ENDPOINT,
					patientMapperRq(body),
					{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
				);
				responsePatientStudy = await upw.postPatientStudy(jwtToken, body.email, body.patient_dto.study);
				if (body.patient_dto.family_group.length !== 0){
					body.patient_dto.family_group.map(async (element) => {
						responsePatienMembertStudy = await upw.postPatientMemberStudy(jwtToken, body.email, element.patient_dto.study, element.id_number ?element.id_number : '');
					})
				}
			}
			return await getProfile(jwtToken, response, decodedToken);
		} catch (error) {
			error.message = `Error mdw patientProfile: ${error.message}`;
			console.log(error);
			if (error.response.status == 404) {
				return [];
			} else throw error;
		}
	};

	async patientSearch(jwtToken, params) {
		try {
			const response = await axios.get(
				this.concatNotNullParam(config.api.user_profile.PATIENT_BS_USER_PROFILE_ENDPOINT, params),
				{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
			);
			let patientList = [];
			response.data.forEach(e => {
				patientList.push({
					id: e.clinicHistoryId,
					id_number: e.idNumber,
					name: e.fullName,
					userLogin: e.userLogin
				})
			});
			return { data: patientList };
		} catch (error) {
			error.message = `Error mdw patientSearch: ${error.message}`;
			console.log(error);
			if (error.response.status == 404) {
				return [];
			} else throw error;
		}
	};

	//	async getPatientClinicHistory(jwtToken, id) {
	//		try {
	//			const decodedToken = decode(jwtToken);
	//			const response = await axios.get(
	//				this.concatNotNullParam(`${config.api.user_profile.PATIENT_USER_PROFILE_ENDPOINT}/detailById`, {id: id}),
	//				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
	//			);
	//			return await getProfile(jwtToken, response, decodedToken);
	//		} catch (error) {
	//			error.message = `Error mdw getPatientClinicHistory: ${error.message}`;
	//			console.log(error);
	//			if(error.response.status == 404) {
	//				return [];
	//			} else throw error;
	//        }
	//	};

	async getPatientClinicHistory(jwtToken, userLogin) {
		try {
			const decodedToken = decode(jwtToken);
			const response = await axios.get(
				this.concatNotNullParam(`${config.api.user_profile.PATIENT_USER_PROFILE_ENDPOINT}/detail`, { userLogin: encodeURIComponent(userLogin) }),
				{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
			);
			return await getProfile(jwtToken, response, decodedToken);
		} catch (error) {
			error.message = `Error mdw getPatientClinicHistory: ${error.message}`;
			throw error;
		}
	};

	async getPatientProfile(jwtToken, patientLogin) {
		try {
			const decodedToken = decode(jwtToken);
			const response = await axios.get(
				`${config.api.user_profile.PATIENT_USER_PROFILE_ENDPOINT}/${patientLogin}`,
				{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
			);
			return await getProfile(jwtToken, response, decodedToken);
		} catch (error) {
			error.message = `Error mdw getPatientProfile: ${error.message}`;
			console.log(error);
			if (error.response.status == 404) {
				return [];
			} else throw error;
		}
	}

	async getPatientOfFamilyGroupProfile(jwtToken, id) {
		try {
			const decodedToken = decode(jwtToken);
			const response = await axios.get(
				this.concatNotNullParam(`${config.api.user_profile.PATIENT_USER_PROFILE_ENDPOINT}/profilebyid`, { id: id }),
				{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
			);
			return await getProfileMember(jwtToken, response, decodedToken);
		} catch (error) {
			error.message = `Error mdw getPatientOfFamilyGroupProfile: ${error.message}`;
			console.log(error);
			if (error.response.status == 404) {
				return [];
			} else throw error;
		}
	}
	// async putFamilyGroup(jwtToken, id, body) {

	// 	try {
	// 		const decodedToken = decode(jwtToken);
	// 		body.email = decodedToken.email;
	// 		let response = {};
	// 		if (body.patient_dto.id) {
	// 			response = await axios.put(
	// 				`${config.api.user_profile.PATIENT_MEMBER_USER_PROFILE_ENDPOINT}/${body.email}`,
	// 				patientMapperRq(body),
	// 				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
	// 			);

	// 		} else {
	// 				response = await axios.post(
	// 				config.api.user_profile.PATIENT_MEMBER_USER_PROFILE_ENDPOINT,
	// 				patientMapperRq(body),
	// 				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
	// 			);
	// 		}
	// 		const gprf= await getProfile(jwtToken, response, decodedToken);
	// 		return gprf; //{status: 'ok',data:responseFG.data};
	// 	} catch (error) {
	// 		error.message = `Error mdw putFamilyGroup: ${error.message}`;
	// 		throw error;
	// 	}

	// };

	async changePlan(jwtToken, body) {
		try {
			const decodedToken = decode(jwtToken);
			let response = {};
			response = await axios.post(
				config.api.user_profile.PATIENT_CHANGE_PLAN_ENDPOINT,
				patientMapperRq(body),
				{ headers: { ...this.commonHeaders, authorization: `Bearer ${jwtToken}` } }
			);
			// console.log(response);
			return response;
		} catch (error) {
			error.message = `Error mdw changePlan: ${error.message}`;
			console.log(error);
			if (error.response.status == 404) {
				return [];
			} else throw error;
		}
	};

}

export default PatientWrapper;
